/**
 * @generated SignedSource<<dd8e7fe8d539cf00527d2d28c0390611>>
 * @relayHash b2ac327a00a50f27b67b27b8cc3b92be
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID c5cc2947640160f183fce6e1671d1576ff26282c66d35b5e655d663d315c05d3

import { ConcreteRequest } from 'relay-runtime';
export type UserActivityPresenceStatus = "editing" | "inactive" | "viewing" | "%future added value";
export type UserActivityResourceType = "journey" | "%future added value";
export type UserActivityPresenceListQueryInput = {
  resourceId: number;
  resourceType: UserActivityResourceType;
  tenantId: number;
};
export type QueriesGetUserActivityPresenceListQuery$variables = {
  input: UserActivityPresenceListQueryInput;
};
export type QueriesGetUserActivityPresenceListQuery$data = {
  readonly userActivityPresenceList: {
    readonly items: ReadonlyArray<{
      readonly metadata: {
        readonly currentSessionStart: string;
        readonly lastHeartbeat: string;
        readonly status: UserActivityPresenceStatus;
      };
      readonly userInfo: {
        readonly email: string;
        readonly id: string;
        readonly name: string;
      };
    }>;
  };
};
export type QueriesGetUserActivityPresenceListQuery = {
  response: QueriesGetUserActivityPresenceListQuery$data;
  variables: QueriesGetUserActivityPresenceListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UserActivityPresenceListQueryResult",
    "kind": "LinkedField",
    "name": "userActivityPresenceList",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserActivityPresenceDto",
        "kind": "LinkedField",
        "name": "items",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserActivityPresenceMetadataDto",
            "kind": "LinkedField",
            "name": "metadata",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currentSessionStart",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastHeartbeat",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserActivityUserInfoDto",
            "kind": "LinkedField",
            "name": "userInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesGetUserActivityPresenceListQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QueriesGetUserActivityPresenceListQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "c5cc2947640160f183fce6e1671d1576ff26282c66d35b5e655d663d315c05d3",
    "metadata": {},
    "name": "QueriesGetUserActivityPresenceListQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "3748056a8c8de213ec0666d395d7621e";

export default node;
